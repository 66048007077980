const modal = {
  Open(id: string, top: number = 0) {
    const modal = document.getElementById(id)
    if (modal) {
      modal.classList.add('show')
      modal.style.display = 'block'
      modal.style.top = top + '%'
    }
  },
  Close(id: string) {
    const modal = document.getElementById(id)
    if (modal) {
      modal.classList.remove('show')
      modal.style.display = 'none'
    }
  },
}

export default modal
