
import modal from '@/components/common/modalManage'
import { CommonResponse } from '@/components/common/model'
import SubscriptionDropdown from '@/components/common/SubscriptionDropdown.vue'
import VueSelect from '@/components/common/VueSelect.vue'
import { Company } from '@/components/master/features/Company/Company.model'
import agent, {
  fortnoxServiceBaseURL,
  masterServiceBaseURL,
} from '@/services/agent'
import authService from '@/services/authService'
import preLoader from '@/services/preLoader'
import { useUserStore } from '@/store/useUserStore'
import { L10n, setCulture } from '@syncfusion/ej2-base'
import {
  CommandColumn,
  ColumnDirective as EColumn,
  ColumnsDirective as EColumns,
  GridComponent as EjsGrid,
  Group,
  Page,
  Resize,
  Search,
  Sort,
  Toolbar,
} from '@syncfusion/ej2-vue-grids'
import axios from 'axios'
import { defineComponent } from 'vue'
import { Module } from '../Module/Module.model'
import EndpointProvider from './EndpointProvider'
import {
  Property,
  PropertySyncLogDetails,
  RunStatus,
  SyncLog,
  SyncLogDetails,
  SyncLogDetailsGetResponse,
  SyncLogGetResponse,
} from './SyncLog.model'
import ModuleEnum from '../../common/ModuleEnum'
import { ResponseBase } from '@/components/businesscentral/common/Common.model'

let runStatus = RunStatus
let _syncLogData: Property = {} as Property
let _syncLogDetailsData: PropertySyncLogDetails = {} as PropertySyncLogDetails
let _syncLogForm: SyncLog = {} as SyncLog
let _moduleList: Module[] = []
const _endpointSelector = new EndpointProvider()
//let _companyList: Company[] = [];

export default defineComponent({
  setup() {
    const userStore = useUserStore()
    return { userStore }
  },
  components: {
    'ejs-grid': EjsGrid,
    'e-columns': EColumns,
    'e-column': EColumn,
    SubscriptionDropdown,
    VueSelect,
  },
  provide: {
    grid: [Toolbar, Resize, Search, CommandColumn, Page, Sort, Group],
  },
  created() {
    agent.setBaseUrl(fortnoxServiceBaseURL)

    this.gridLocalize()
  },
  data() {
    return {
      moduleList: _moduleList,
      data: _syncLogData,
      form: _syncLogForm,
      moduleId: 0,
      isUpdate: false,
      isTableLoad: false,
      confirmTitle: '',
      confirmIsDelete: false,
      selectedState: null,
      companyId: 0,
      companies: [] as Company[],
      subscriptionId: '',
      details: {
        syncLogMasterId: 0,
        data: _syncLogDetailsData,
        searchOptions: {
          fields: [
            //'Id',
            //'LogId',
            // "Entity",
            'StartedAt',
            'CompletedAt',
            //'RunStatusId',
            'HasError',
            'ErrorMessage',
            //'RowSynced',
          ],
          operator: 'contains',
          ignoreCase: true,
        },
        toolbar: ['Search'],
        pageSettings: {
          pageSizes: [10, 20, 50, 100],
          pageSize: 50,
          pageCount: 5,
          state: { skip: 0, take: 50 } as any,
        },
      },

      searchOptions: {
        fields: [
          'Id',
          //   "CompanyId",
          'RequestedAt',
          'RequestedBy',
          'StartedAt',
          'CompletedAt',
          //'RunStatusId',
          //   "HasError",
          'ErrorMessage',
          //   "IsFullSync",
          'JobRunId',
          //   "TryCount",
          'ForceSyncDate',
        ],
        operator: 'contains',
        ignoreCase: true,
      },
      toolbar: ['Search'],
      pageSettings: {
        pageSizes: [10, 20, 50, 100],
        pageSize: 50,
        pageCount: 5,
        skip: 0,
        take: 50,
      },
      locale: '',
      runStatus: runStatus,
    }
  },
  mounted() {
    this.dataStateChange(
      this.initState(this.pageSettings.skip, this.pageSettings.take)
    )
    if (!this.userStore.userInfo.isHost) {
      this.loadModule()
    }
  },
  watch: {
    '$i18n.locale': {
      handler(newVal, oldVal) {
        this.gridLocalize()
      },
      immediate: true,
    },
  },
  methods: {
    initState(skip: number = 0, take: number): any {
      let state = {
        skip: skip,
        take: take,
      }
      return state
    },
    initStateDetails(): any {
      let state = {
        skip: 0,
        take: this.details.pageSettings.pageSize,
      }
      return state
    },
    copyToClipboard(errorMessage: string) {
      navigator.clipboard.writeText(errorMessage)
    },
    actionColumnClick(rowData: SyncLog) {
      this.details.syncLogMasterId = rowData.id
      this.open()
      this.loadSyncLogDetails(this.initStateDetails())
    },
    dataStateChange(state: any) {
      this.pageSettings.skip = state.skip
      this.pageSettings.take = state.take

      this.gridLocalize()

      if (this.moduleId == 0) return
      this.loadSyncLog(state)
    },
    dataStateChangeDetails(state: any) {
      this.loadSyncLogDetails(state)
    },
    async loadSyncLog(state: any) {
      if (state.action) {
        delete state.action
      }
      const { isHost, subscriptionId } = this.userStore.userInfo
      const subParams = {
        subscriptionId: isHost ? this.subscriptionId : subscriptionId,
        moduleId: this.moduleId,
        companyId: this.companyId,
      }

      const gridSyncLog: any = this.$refs.gridSyncLog
      if (gridSyncLog) gridSyncLog.hideSpinner()

      const syncLogMasterUrl = _endpointSelector.getMasterLogEndpoint(
        this.moduleList.filter((x) => x.id == this.moduleId)[0]?.name
      )

      preLoader.show()
      if (
        this.moduleList.filter((x) => x.id == this.moduleId)[0]?.name ==
        ModuleEnum.BusinessCentral.id
      ) {
        const response = await agent.post<ResponseBase<SyncLog>>(
          syncLogMasterUrl,
          { ...state, ...subParams }
        )
        if (response) {
          response.data?.forEach((log) => {
            log.companyName =
              this.companies.find((c) => c.id == log.companyId)?.name ?? ''
          })

          this.data = {
            result: response.data,
            count: response.recordCount ?? 0,
          }
        }
      } else {
        const response = await axios.post<SyncLogGetResponse>(
          syncLogMasterUrl,
          { ...state, ...subParams },
          {
            headers: { Authorization: `Bearer ${authService.getToken()}` },
          }
        )
        try {
          if (response) {
            // assign respective company names
            response.data?.result?.result?.forEach((log) => {
              log.companyName =
                this.companies.find((c) => c.id == log.companyId)?.name ?? ''
            })

            this.data = response.data?.result
          }
        } catch (error) {
          console.error(error)
        }
      }

      preLoader.hide()
    },

    async loadSyncLogDetails(state: any) {
      if (state.action) {
        delete state.action
      }

      const gridSyncLogDetails: any = this.$refs.gridSyncLogDetails
      if (gridSyncLogDetails) gridSyncLogDetails.hideSpinner()

      const syncLogMasterId = this.details.syncLogMasterId
      const moduleId = this.moduleId

      const syncLogDetailsEndpoint =
        _endpointSelector.getMasterLogDetailsEndpoint(
          this.moduleList.filter((x) => x.id == this.moduleId)[0]?.name
        )

      preLoader.show()
      if (
        this.moduleList.filter((x) => x.id == this.moduleId)[0]?.name ==
        ModuleEnum.BusinessCentral.id
      ) {
        const response = await agent.post<ResponseBase<SyncLogDetails>>(
          syncLogDetailsEndpoint,
          { ...state, syncLogMasterId, moduleId }
        )
        if (response) {
          this.details.data = {
            result: response.data,
            count: response.recordCount ?? 0,
          }
        }
        preLoader.hide()
      } else {
        axios
          .post<SyncLogDetailsGetResponse>(
            syncLogDetailsEndpoint,
            { ...state, syncLogMasterId, moduleId },
            {
              headers: { Authorization: `Bearer ${authService.getToken()}` },
            }
          )
          .then((response) => {
            if (response) {
              this.details.data = response.data.result
              preLoader.hide()
            }
          })
          .catch(() => {
            preLoader.hide()
          })
      }
    },
    onCompanyChange() {
      //this.companyId = companyId;
      this.loadSyncLog(
        this.initState(this.pageSettings.skip, this.pageSettings.take)
      )
    },
    onSubscriptionChanged() {
      this.moduleId = 0
      this.companyId = 0
      this.data = _syncLogData
      this.loadModule()
    },
    async changeModule() {
      await this.loadCompanies()
      await this.loadSyncLog(
        this.initState(this.pageSettings.skip, this.pageSettings.take)
      )
    },
    gridLocalize() {
      this.locale = 'en-grid'
      if (this.$i18n.locale == 'se') {
        setTimeout(() => {
          import(`@/assets/sv.json`).then((module) => {
            const localText = module.default
            this.locale = 'sv'
            setCulture('sv')
            L10n.load(localText)
          })
        })
      }
    },
    open() {
      modal.Open('requestedModal')
    },
    close() {
      this.details.syncLogMasterId = 0
      modal.Close('requestedModal')
    },
    async loadModule() {
      const { isHost, subscriptionId } = this.userStore.userInfo
      const subId = isHost ? this.subscriptionId : subscriptionId

      const response = await axios.get<CommonResponse<Module>>(
        `${masterServiceBaseURL}v1/Module/GetModules?subscriptionId=${subId}`,
        {
          headers: { Authorization: `Bearer ${authService.getToken()}` },
        }
      )

      this.moduleList = response.data.result
    },
    async loadCompanies() {
      const { isHost, subscriptionId } = this.userStore.userInfo
      const subId = isHost ? this.subscriptionId : subscriptionId

      preLoader.show()
      const response = await axios.get<CommonResponse<Company>>(
        `${masterServiceBaseURL}v1/Subscription/companies?subscriptionId=${subId}&moduleId=${this.moduleId}`,
        {
          headers: { Authorization: `Bearer ${authService.getToken()}` },
        }
      )

      try {
        this.companies = response.data.result
      } catch (error) {
        console.error(error)
      }

      preLoader.hide()
    },
    getHoverMessage(statusId: number, hasError: boolean) {
      if (statusId == runStatus.Completed) {
        if (hasError) {
          return this.$t('completedWithErrors')
        }
        return this.$t('completed')
      } else if (statusId == runStatus.Running) {
        return this.$t('running')
      } else if (statusId == runStatus.Pending) {
        return this.$t('pending')
      } else {
        return this.$t('failed')
      }
    },
    getRunStatusIcon(statusId: number, hasError: boolean) {
      if (statusId == runStatus.Completed) {
        if (hasError) {
          return 'fas fa-exclamation-circle text-danger'
        }
        return 'fas fa-check-circle text-success'
      } else if (statusId == runStatus.Running) {
        return 'fas fa-spinner text-warning'
      } else if (statusId == runStatus.Pending) {
        return 'fas fa-clock text-info'
      } else {
        return 'fas fa-times-circle text-danger'
      }
    },
  },
})
