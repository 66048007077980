
import modal from '@/components/common/modalManage'
import agent, { masterServiceBaseURL } from '@/services/agent'
import toasterService from '@/services/toasterService'
import { L10n, setCulture } from '@syncfusion/ej2-base'
import {
  CommandColumn,
  ColumnDirective as EColumn,
  ColumnsDirective as EColumns,
  GridComponent as EjsGrid,
  Freeze,
  Group,
  Page,
  Resize,
  Sort,
  Toolbar,
} from '@syncfusion/ej2-vue-grids'
import { defineComponent } from 'vue'
import {
  ChipListComponent,
  ChipsDirective,
  ChipDirective,
} from '@syncfusion/ej2-vue-buttons'
import {
  BaseResponse,
  CreateEntitySetupModel,
  EntityResponseModel,
  EntitySetupModel,
  UpdateEntitySetupModel,
} from './EntitySetup.model'
import { TABLE_SETTINGS } from './constants'
import { API_KEY } from './constants'
import { DialogUtility } from '@syncfusion/ej2-vue-popups'
import authService from '@/services/authService'

let Confirmation: any = undefined
export default defineComponent({
  components: {
    'ejs-grid': EjsGrid,
    'e-columns': EColumns,
    'e-column': EColumn,
    'ejs-chiplist': ChipListComponent,
    'e-chips': ChipsDirective,
    'e-chip': ChipDirective,
  },
  provide: {
    grid: [Toolbar, Resize, CommandColumn, Page, Sort, Group, Freeze],
  },
  created() {
    agent.setBaseUrl(masterServiceBaseURL)
    this.gridLocalize()
  },
  data() {
    return {
      locale: '',
      data: {} as EntityResponseModel,
      form: [] as EntitySetupModel[],
      editModel: {} as EntitySetupModel,
      isUpdate: false,
      selectedState: null,
      searchOptions: TABLE_SETTINGS.SEARCH_OPTIONS,
      toolbar: TABLE_SETTINGS.TOOLBARS,
      pageSettings: TABLE_SETTINGS.PAGE_SETTINGS,
      commands: TABLE_SETTINGS.COMMANDS,
    }
  },
  async mounted() {
    this.dataStateChange(this.initState(this.pageSettings.state))
  },
  computed: {
    isSubmitDisabled() {
      if (this.form.length === 0) return true
      for (const entity of this.form) {
        if (!entity.name || !entity.urlSuffix || !entity.tableName) {
          return true
        }
        for (const child of entity.children) {
          if (!child.name || !child.urlSuffix || !child.tableName) {
            return true
          }
        }
      }
      return false
    },
  },
  unmounted() {},
  methods: {
    gridLocalize() {
      this.locale = 'en-grid'
      if (this.$i18n.locale == 'se') {
        setTimeout(() => {
          import(`@/assets/sv.json`).then((module) => {
            const localText = module.default
            this.locale = 'sv'
            setCulture('sv')
            L10n.load(localText)
          })
        })
      }
    },
    closeModal() {
      this.resetForm()
      this.isUpdate = false
      modal.Close('PageModal')
    },
    resetForm() {
      // Finally, clear the entire form array
      this.form = []
    },
    initState(params: any): any {
      const state = {
        ...params,
      }
      return state
    },
    removeEntityFromList(entity: EntitySetupModel) {
      this.form = this.form.filter(
        (item) => item !== entity && item.id !== entity.id
      )
    },
    removeChildFromList(index: any, entity: EntitySetupModel) {
      this.form[index].children = this.form[index].children.filter(
        (item) =>
          item !== entity && item.parentEntityTableName !== entity.tableName
      )
    },
    async upsertEntity(event: any) {
      event.preventDefault()
      if (this.isUpdate) {
        this.onEdit()
      } else {
        this.onSave()
      }
    },
    async onSave() {
      let payload: CreateEntitySetupModel = {
        entities: this.form,
        loggedInUser: await authService.getUserEmail(),
      }
      const response = await agent.post<BaseResponse>(API_KEY.POST, payload)
      if (response && response.result) {
        toasterService.success(this.$t('msgSave'))
        this.loadData(this.initState(this.pageSettings.state))
        this.closeModal()
      }
    },
    async onEdit() {
      let payload: UpdateEntitySetupModel = {
        entity: this.form[0],
        loggedInUser: await authService.getUserEmail(),
      }
      const response = await agent.put<BaseResponse>(API_KEY.PUT, payload)
      if (response && response.result) {
        toasterService.success(this.$t('msgSave'))
        this.loadData(this.initState(this.pageSettings.state))
        this.closeModal()
      }
    },
    commandClick: function (args: { commandColumn: any; rowData: any }) {
      this.isUpdate = false
      const app = this
      if (args) {
        switch (args.commandColumn.type) {
          case 'Delete':
            Confirmation = DialogUtility.confirm({
              title: this.$t('deleteConfiramtion'),
              content: this.$t('msgSureWantToDelete'),
              okButton: {
                text: this.$t('ok'),
                click: async function () {
                  Confirmation.hide()
                  app.onDelete(args.rowData.id)
                },
              },
              cancelButton: { text: this.$t('cancel') },
              showCloseIcon: true,
              closeOnEscape: true,
              zIndex: 10000,
              animationSettings: { effect: 'Zoom' },
            })
            break
          case 'Edit':
            this.form.push(JSON.parse(JSON.stringify(args.rowData)))
            this.isUpdate = true
            modal.Open('PageModal')
            break
          default:
            break
        }
      }
    },
    async onDelete(id: number) {
      const response = await agent.delete<BaseResponse>(API_KEY.DELETE(id))
      if (response && response.result) {
        toasterService.success(this.$t('msgDelete'))
        this.loadData(this.initState(this.pageSettings.state))
      }
    },
    addChild(parentIndex: any) {
      var maxId =
        this.form.length > 0
          ? this.form.reduce(
              (max, entity) => (entity.id > max ? entity.id : max),
              0
            )
          : 0
      const entity = {
        id: maxId + 1,
        name: '',
        tableName: '',
        urlSuffix: '',
        filterPropertyName: '',
        parentEntityTableName: this.form[parentIndex].tableName,
      } as EntitySetupModel
      this.form[parentIndex].children.push({ ...entity })
    },
    dataStateChange(state: any) {
      this.pageSettings.state = state
      this.gridLocalize()
      this.loadData(state)
    },
    async addEntityToList() {
      var maxId =
        this.form.length > 0
          ? this.form.reduce(
              (max, entity) => (entity.id > max ? entity.id : max),
              0
            )
          : 0

      const entity = {
        id: maxId + 1,
        name: '',
        urlSuffix: '',
        tableName: '',
        filterPropertyName: '',
        children: [] as EntitySetupModel[],
      } as EntitySetupModel
      this.form.push({ ...entity })
    },
    openModal() {
      modal.Open('PageModal')
    },
    async loadData(state: any) {
      if (state.action) {
        delete state.action
      }
      const gridPage: any = this.$refs.gridPage
      if (gridPage) gridPage.hideSpinner()
      const response = await agent.post<BaseResponse>(API_KEY.GET, state)
      if (response && response.result) {
        this.data = response.result
      }
    },
  },
})
