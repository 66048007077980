export const API_KEY = {
  GET: 'v1/entityconfig/get',
  POST: 'v1/entityconfig/create',
  PUT: 'v1/entityconfig/update',
  DELETE: (id: number) => `v1/entityconfig/delete?id=${id}`,
}

export const TABLE_SETTINGS = {
  SEARCH_OPTIONS: {
    fields: ['Name'],
    operator: 'contains',
    ignoreCase: true,
  },
  PAGE_SETTINGS: {
    pageSizes: [10, 20, 50, 100],
    pageSize: 50,
    pageCount: 5,
    state: {
      skip: 0,
      take: 50,
    } as any,
  },
  COMMANDS: [
    {
      type: 'Edit',
      buttonOption: { cssClass: 'e-flat', iconCss: 'e-edit e-icons' },
    },
    {
      type: 'Delete',
      buttonOption: { cssClass: 'e-flat', iconCss: 'e-delete e-icons' },
    },
  ],
  TOOLBARS: ['Search'],
}
